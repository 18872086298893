const app = {
  state: {
    isProcessing: false,
  },
  mutations: {
    SET_IS_PROCESSING: (state, isProcessing) => {
      state.isProcessing = isProcessing;
    },
  },
  actions: {
    setIsProcessing({ commit }, isProcessing) {
      commit('SET_IS_PROCESSING', isProcessing);
    },
  },
};

export default app;
