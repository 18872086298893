const project = {
  state: {
    projectId: "",
    projectName: "",
    projectTotalStorage: 0,
    projectUsedStorage: 0,
  },
  mutations: {
    SET_PROJECT_STATE: (state, project) => {
      console.log(project.usedStorage)
      state.projectId = project.id;
      state.projectName = project.name;
      state.projectTotalStorage = project.totalStorage;
      state.projectUsedStorage = project.usedStorage;
    },
  },
  actions: {
    setProjectState({ commit }, project) {
      commit('SET_PROJECT_STATE', project);
    },
  }
};

export default project;
