import axios from "axios";

const apiBaseURL = process.env.VUE_APP_API_URL
const fileBaseURL = process.env.VUE_APP_FILE_URL

const service = axios.create({
  baseURL: apiBaseURL,
  timeout: 300000,
});

// request interceptors
service.interceptors.request.use(
  (config) => config,
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptors
service.interceptors.response.use(
  (response) => {
    console.log("[Axios]: response ");
    return response.data;
  },
  (error) => {
    const { response = {} } = error;
    const { data = null } = response;
    console.log("[Axios:Error]: ", data);
    return Promise.reject(data || error);
  }
);

export default async function api(
  url,
  data,
  method = "POST",
  headers = {},
  params = {}
) {
  let token = "";
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("auth_token");
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
    headers["Access-Control-Allow-Origin"] = "*";
  }

  service.defaults.baseURL = apiBaseURL;

  const result = await service.request({
    method,
    url,
    data,
    params,
    headers,
  });

  return result;
}

export async function file(
  url,
  data,
  method = "POST",
  headers = {},
  params = {}
) {
  let token = "";
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("file_token");
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
    headers["Access-Control-Allow-Origin"] = "*";
  }

  service.defaults.baseURL = fileBaseURL;

  const result = await service.request({
    method,
    url,
    data,
    params,
    headers,
  });

  return result;
}
