import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router/router.js";
import Store from "./store/index";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueApexCharts from "vue3-apexcharts";

import {
  faHouse,
  faUser,
  faMagnifyingGlass,
  faSquareCaretDown,
  faLayerGroup,
  faFile,
  faWrench,
  faHammer,
  faWarehouse,
  faUsers,
  faUpload,
  faHeart,
  faEye,
  faDownload,
  faLock,
  faUnlock,
  faPlus,
  faSliders,
  faCloudArrowUp,
  faGear,
  faCircleInfo,
  faCheck,
  faPenToSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);

library.add(
  faHouse,
  faUser,
  faMagnifyingGlass,
  faSquareCaretDown,
  faLayerGroup,
  faFile,
  faWrench,
  faHammer,
  faWarehouse,
  faUsers,
  faUpload,
  faHeart,
  faEye,
  faDownload,
  faLock,
  faUnlock,
  faPlus,
  faSliders,
  faCloudArrowUp,
  faCheck,
  faGear,
  faCircleInfo,
  faPenToSquare
);

app.use(VueApexCharts);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(ElementPlus);
app.use(Router);
app.use(Store);
app.mount("#app");
