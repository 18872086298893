import api from "@/utils/request";

export function getUser(_id) {
  return api("/user/getUser", {}, "GET", {}, { userId: _id });
}

export function createUser(username, account, password) {
  const params = {
    "user": {
      "account": account,
      "name": username,
    },
    "password": password,
  }

  return api("/user/createUser", params);
}

export function updateUserProjectAuth(account, projectId, authLevel) {
  const params = {
    "targetUserAccount": account,
    "projectId": projectId,
    "authLevel": authLevel,
  }

  return api('/user/updateUserProjectAuth', params, 'PUT')
}

export function deleteUserProjectAuth(userId, projectId) {
  const params = {
    "targetUserId": userId,
    "projectId": projectId,
  }

  return api('/user/deleteUserProjectAuth', params, 'PUT')
}
