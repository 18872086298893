<template>
  <div v-loading.fullscreen.lock="isProcessing" element-loading-text="Loading..."
    element-loading-background="rgba(122, 122, 122, 0.8)">
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlatformHeader from './Header.vue';

export default {
  name: 'PlatformDefault',
  components: {
    PlatformHeader,
  },
  computed: {
    ...mapGetters([
      'isProcessing',
    ]),
  },
};
</script>
