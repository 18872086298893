<template>
  <el-menu background-color="gray" text-color="aliceblue" active-text-color="white" class="el-menu-demo" mode="horizontal"
    id="header" :ellipsis="false">
    <el-menu-item index="0" class='item' @click="home"><font-awesome-icon style="padding: 1rem"
        icon='fa-solid fa-house' /></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="2" class='item' @click="dataset">Datasets</el-menu-item>
    <el-menu-item index="3" class='item' @click="model">Models</el-menu-item>
    <el-menu-item index="4" v-if="isLoggedIn" class='item' @click="project">Projects</el-menu-item>
    <el-menu-item index="5" v-if="!isLoggedIn" class='item' @click="login"><font-awesome-icon
        icon="fa-solid fa-user" /></el-menu-item>
    <el-sub-menu index="5" v-else class='item' v-show="isLoggedIn">
      <template #title>
        <span id="name">
          {{ username }}
        </span>
      </template>
      <el-menu-item index="4-1">Profile</el-menu-item>
      <el-menu-item v-if="account === 'admin'" index="4-2" @click="admin">Admin</el-menu-item>
      <el-menu-item v-if="account === 'admin'" index="4-3">
        <el-link style="color:aliceblue;" :href=metricsURL target="_blank">
          Server Metrics
        </el-link>
      </el-menu-item>
      <el-menu-item v-if="account === 'admin'" index="4-4">
        <el-link style="color:aliceblue;" :href=logURL target="_blank">
          Server Logs
        </el-link>
      </el-menu-item>
      <el-menu-item index="4-5" @click="logout">Logout</el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PlatformHeader',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'currrentUser',
      'username',
      'account'
    ]),
    logURL() {
      return process.env.VUE_APP_LOG_URL;
    },
    metricsURL() {
      return process.env.VUE_APP_METRIC_URL;
    }
  },
  methods: {
    login() {
      this.$router.push({ name: 'Login' });
    },
    home() {
      this.$router.push({ name: 'Home' });
    },
    project() {
      this.$router.push({ name: 'Project' });
    },
    dataset() {
      this.$router.push({ name: 'Dataset' });
    },
    model() {
      this.$router.push({ name: 'Model' });
    },
    admin() {
      this.$router.push({ name: 'Admin' });
    },
    async logout() {
      this.$store.dispatch('setIsProcessing', true);
      try {
        await this.$store.dispatch('logout');
        this.$message({ type: 'success', message: 'logout successfully', duration: 1000 });
        this.$router.push({ name: 'Home' });
      } catch (error) {
        this.$message({ type: 'error', message: 'logout fail', duration: 1000 });
        console.log(error.message);
      }
      this.$store.dispatch('setIsProcessing', false);
    },
  }
}
</script>

<style scoped>

.item {
  font-size: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.flex-grow {
  flex-grow: 1;
}


#name {
  font-size: 2rem;
  color: aliceblue;
  letter-spacing: 0.05rem;
  justify-content: center;
}
</style>
