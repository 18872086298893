import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/home/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../components/Register.vue'),
  },
  {
    path: '/project',
    name: 'Project',
    component: () => import('../components/project/Project.vue'),
  },
  {
    path: '/project/:projectId',
    component: () => import('../components/project/ProjectInfo.vue'),
    name: 'ProjectInfo',
    children: [
      {
        path: '',
        name: 'ProjectHome',
        component: () => import('../components/project/ProjectDescription.vue'),
      },
      {
        path: 'model',
        name: 'ProjectModelList',
        component: () => import('../components/project/ProjectModelList.vue'),
      },
      {
        path: 'model/modify/:modelId',
        name: 'ProjectModifyModel',
        component: () => import('../components/model/ModelModify.vue'),
      },
      {
        path: 'model/:modelId',
        name: 'ProjectModel',
        component: () => import('../components/model/Model.vue'),
      },
      {
        path: 'dataset',
        name: 'ProjectDatasetList',
        component: () => import('../components/project/ProjectDatasetList.vue'),
      },
      {
        path: 'dataset/modify/:datasetId',
        name: 'ProjectModifyDataset',
        component: () => import('../components/dataset/DatasetModify.vue'),
      },
      {
        path: 'dataset/:datasetId',
        name: 'ProjectDataset',
        component: () => import('../components/dataset/Dataset.vue'),
      },
      {
        path: 'member',
        name: 'ProjectMember',
        component: () => import('../components/project/ProjectMember.vue'),
      },
      {
        path: 'storage',
        name: 'ProjectStorage',
        component: () => import('../components/project/ProjectStorage.vue'),
      },
    ]
  },
  {
    path: '/model/:modelId',
    name: 'ModelInfo',
    component: () => import('../components/model/Model.vue'),
  },
  {
    path: '/dataset/:datasetId',
    name: 'DatasetInfo',
    component: () => import('../components/dataset/Dataset.vue'),
  },
  {
    path: '/dataset',
    name: 'Dataset',
    component: () => import('../components/Dataset.vue'),
  },
  {
    path: '/model',
    name: 'Model',
    component: () => import('../components/Model.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../components/project/ProjectAdmin.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
