import { login } from '@/api/session';
import { getUser } from '@/api/user';

const user = {
  state: {
    id: null,
    account: null,
    username: null,
    data: {},
    isLoggedIn: false,
  },
  mutations: {
    SET_LOGIN_STATE: (state, currentUser) => {
      state.isLoggedIn = true;
      state.username = currentUser.user.name;
      state.account = currentUser.user.account;
      state.id = currentUser.user.id;
      state.data = currentUser;
    },
    SET_LOGOUT_STATE: (state) => {
      state.id = null;
      state.username = null;
      state.account = null;
      state.data = {};
      state.isLoggedIn = false;
    },
  },
  actions: {
    login({ commit }, { userAccount, userPassword }) {
      const loginProcess = async() => {
        const { token, id } = await login(userAccount, userPassword);
        window.localStorage.setItem('auth_token', token);
        const currentUser = await getUser(id);
        commit('SET_LOGIN_STATE', currentUser);
      };
      return loginProcess();
    },
    logout({ commit }) {
      const logoutProcess = async() => {
        window.localStorage.removeItem('auth_token');
        window.localStorage.removeItem('file_token');
        commit('SET_LOGOUT_STATE');
      };
      return logoutProcess();
    },
  }
}

export default user;
