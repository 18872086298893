import api from '@/utils/request';

export function login(userAccount, userPassword) {
    return api('/session/login', { userAccount, userPassword });
}

export function getUploadModelToken(projectId, modelId, isModel=true) {
    const params = {
        "projectId": projectId,
        "modelId": modelId,
        "isModel": isModel,
    }
    return api("/session/getUploadModelToken", params);
}

export function getUploadDatasetToken(projectId, datasetId, isModel=false) {
    const params = {
        "projectId": projectId,
        "modelId": datasetId, // just for implementation convenient, we put dataset id as model id in the file path.
        "isModel": isModel,
    }
    return api("/session/getUploadDatasetToken", params);
}
