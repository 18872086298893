import { createStore } from "vuex";
import user from './modules/user';
import app from './modules/app';
import project from './modules/project';
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    modules: {
      app,
      user,
      project,
    },
    getters: {
      isProcessing: (state) => state.app.isProcessing,
      currentUser: (state) => state.user.data,
      isLoggedIn: (state) => state.user.isLoggedIn,
      userId: (state) => state.user.id,
      username: (state) => state.user.username,
      account: (state) => state.user.account,
      projectName: (state) => state.project.projectName,
      projectId: (state) => state.project.projectId,
      projectTotalStorage: (state) => state.project.projectTotalStorage,
      projectUsedStorage: (state) => state.project.projectUsedStorage,
    },
    plugins: [createPersistedState()],
});

export default store;
